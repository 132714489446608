import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@misc/constants';
import { HttpService } from '@services/http.service';
import { Settings } from '@models/_base.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(@Inject(APP_CONFIG) private config, private http: HttpService) {}

  get(id?: number): Observable<Settings> {
    return this.http.get(`${this.config.apiUrl}/api/settings/${id}`);
  }

  set(params: Settings, id?: number): Observable<Settings> {
    return this.http.patch(`${this.config.apiUrl}/api/settings/${id}`, params);
  }
}
