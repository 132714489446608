import { Injectable } from '@angular/core';
import { UserService } from '@services/api/modules/user.service';
import { LocationService } from '@services/api/modules/location.service';
import { PostService } from '@services/api/modules/post.service';
import { SettingsService } from '@services/api/modules/settings.service';
import { AdminService } from '@services/api/modules/admin.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    public user: UserService,
    public admin: AdminService,
    public location: LocationService,
    public post: PostService,
    public settings: SettingsService
  ) {}
}
