import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@misc/constants';
import { HttpService } from '@services/http.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { List } from '@models/_base.model';
import { Params } from '@angular/router';
import { Location, LocationList } from '@models/location.model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(@Inject(APP_CONFIG) private config, private http: HttpService) {}

  getList(params?: Params): Observable<LocationList> {
    params = Object.assign({ postsCount: '>=0', population: '>=0' }, params);
    return this.http.get(`${this.config.apiUrl}/api/location`, { params }).pipe(
      map((list: List): LocationList => new LocationList(list, Location)),
      catchError((): Observable<LocationList> => of(new LocationList({}, Location)))
    );
  }
}
