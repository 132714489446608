import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { ApiService } from '@services/api/api.service';
import { LocationList } from '@models/location.model';
import { BANNED_SORT } from '@misc/constants';

export interface TableButton {
  ico: (data: any) => string;
  text?: string;
  color?: string;
  onClick?: (event: MouseEvent, data?: any) => void;
}

export interface ColumnTable {
  columnName: string;
  title: string;
  select?: {
    list: any;
    placeholder: string;
    onChange: (event: any) => void;
    formControl?: FormControl;
  };
  input?: {
    placeholder: 'Search';
    onChange?: (value: string) => void;
    formControl?: FormControl;
  };
  autocomplete?: {
    listName: string;
    placeholder: 'Search';
    onInput: (value: string) => void;
    formControl?: FormControl;
    onChange?: (value: any) => void;
  };
  sticky?: boolean;
  button?: TableButton[];
  simpleIcons?: (data: any) => { src: string }[];
  link?: (event: MouseEvent, data: any) => void;
  getValue?: (data: any) => string;
  defaultToggle?: (data: any) => boolean;
  toggle?: (data: any, event: any) => void;
  sortIsBanned?: boolean;
}

export interface AutocompleteList {
  [key: string]: any[];
}

export interface SelectList {
  title: string;
  id: string;
}

@Component({
  selector: 'table-custom',
  templateUrl: './table-custom.component.html',
  styleUrls: ['./table-custom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCustomComponent implements OnInit {
  @Input() dataSource: any[];
  @Input() column: ColumnTable[];
  @Input() autocompleteList: { [key: string]: any };
  @Input() length: number;
  @Input() pageSize: number;
  @Input() link: string[];
  @Output() changePage: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output() changeBannedSort = new EventEmitter();
  @Input() activePage: number;
  @Input() noHeader: boolean;
  @Input() lightText: boolean;
  baseFormControl = new FormControl();
  maxDate = new Date();

  public BANNED_SORT = BANNED_SORT;
  listCities: SelectList[] = [];
  filteredOptions: Observable<SelectList[]>;
  cityControl = new FormControl();

  get displayedColumns(): string[] {
    return this.column.map((column: ColumnTable) => column.columnName);
  }

  constructor(private api: ApiService) {}

  ngOnInit(): void {
    this.column.forEach((item: ColumnTable) => {
      if (item.columnName === 'city' && item.input) {
        this.cityControl = item.input.formControl;
      }
    });

    this.api.location.getList({ 'per-page': 100 }).subscribe((data: LocationList) => {
      this.listCities = data.entities.map(({ locality, id }) => Object.assign({ ['title']: locality, ['id']: id }));
      this.listCities.sort(this.sortCities);
    });
    this.filteredOptions = this.cityControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(filterValue: string): SelectList[] {
    return this.listCities.filter(option => option.title.includes(filterValue));
  }

  onChangePage($event: any): void {
    this.changePage.emit($event);
  }

  onBannedSortChange($event): void {
    this.changeBannedSort.emit($event);
  }

  getRoute(id: string): string[] {
    if (this.link) {
      return [...this.link, id];
    }
    return null;
  }

  getFragmentLink(): string {
    return location.hash.substr(1).trim() || null;
  }

  displayFnSelect(option: any): string | undefined {
    return option && option.name;
  }

  isColumnName(columnName: string, name: string): boolean {
    return columnName === name;
  }

  sortCities(a: SelectList, b: SelectList): number {
    const nameA = a.title.toLowerCase();
    const nameB = b.title.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }
}
