import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@app/guards/auth.guard';
import { UnauthGuard } from '@app/guards/unauth.guard';
import { ViewWrapperComponent } from '@views/view-wrapper/view-wrapper.component';
import { MainWrapperComponent } from '@views/main/main-wrapper/main-wrapper.component';
import { AuthWrapperComponent } from '@views/auth/auth-wrapper/auth-wrapper.component';
import { navigation } from '@views/main/admin/navigation';
import { MeResolver } from '@app/resolvers/me.resolver';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: { me: MeResolver },
    component: ViewWrapperComponent,
    data: { navigation },
    children: [
      {
        path: '',
        component: MainWrapperComponent,
        loadChildren: () => import('@views/main/admin/admin.module').then(m => m.AdminModule)
      }
    ]
  },
  {
    path: 'auth',
    canActivate: [UnauthGuard],
    component: ViewWrapperComponent,
    children: [
      {
        path: '',
        component: AuthWrapperComponent,
        loadChildren: () => import('@views/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [MeResolver]
})
export class AppRoutingModule {}
