import { InjectionToken } from '@angular/core';
import { Validators } from '@angular/forms';

export interface NavigationLink {
  label: string;
  url: string[];
  icon?: string;
}

export const APP_CONFIG = new InjectionToken<string>('APP_CONFIG');

export enum STORAGE_KEYS {
  TOKEN = 'token'
}

export const VALIDATORS_SET = Object.freeze({
  EMAIL: Validators.compose([Validators.email]),
  PASSWORD: Validators.compose([Validators.required])
});

export enum BANNED_SORT {
  ALL = 'all',
  NON_BANNED = 0,
  IS_BANNED = 1
}
