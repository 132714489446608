import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@misc/constants';
import { HttpService, ServicesConfig } from '@services/http.service';
import { Observable, of } from 'rxjs';
import { Stats, User, UsersList } from '@models/user.model';
import { catchError, map } from 'rxjs/operators';
import { Entity, List } from '@models/_base.model';
import { Params } from '@angular/router';
import { Post, PostList } from '@models/post.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(@Inject(APP_CONFIG) private config, private http: HttpService) {}

  getMe(services?: ServicesConfig): Observable<User> {
    return this.http
      .get(`${this.config.apiUrl}/api/users/me`, {}, services)
      .pipe(
        map((user: Entity): User => new User(user)),
        catchError((): Observable<User> => of({} as User))
      );
  }

  getList(params?: Params): Observable<UsersList> {
    return this.http.get(`${this.config.apiUrl}/api/users`, { params }).pipe(
      map((list: List): UsersList => new UsersList(list, User)),
      catchError((): Observable<UsersList> => of(new UsersList({}, User)))
    );
  }

  getOneById(id, params?: Params): Observable<User> {
    return this.http
      .get(
        `${this.config.apiUrl}/api/users/${id}`,
        { params },
        { skipErrorNotification: true }
      )
      .pipe(
        map((user: Entity): User => new User(user)),
        catchError((): Observable<User> => of({} as User))
      );
  }

  getStatsById(id, params?: Params): Observable<Stats> {
    return this.http
      .get(
        `${this.config.apiUrl}/api/users/${id}/stats`,
        { params },
        { skipErrorNotification: true }
      )
      .pipe(
        map((stats: Stats): Stats => stats),
        catchError((): Observable<Stats> => of({} as Stats))
      );
  }

  getPostsById(id, contextId, params?: Params): Observable<PostList> {
    return this.http
      .get(
        `${this.config.apiUrl}/api/posts/feeds/user/${id}/${contextId}`,
        { params },
        {}
      )
      .pipe(
        map((posts): PostList => new PostList(posts, Post)),
        catchError((): Observable<PostList> => of({} as PostList))
      );
  }

  ban(id: number, isBanned: boolean): Observable<void> {
    return this.http.patch(`${this.config.apiUrl}/api/users/${id}/ban`, {
      isBanned
    });
  }
  banDevice(id: number, isBannedDevice: boolean): Observable<void> {
    return this.http.patch(`${this.config.apiUrl}/api/users/${id}/ban_device`, {
      isBannedDevice
    });
  }
}
