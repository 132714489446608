import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from '@app/app.component';
import { AppRoutingModule } from '@app/app-routing.module';
import { CommonComponentsModule } from '@components/common/common-components.module';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '@pipes/pipes.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '@components/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '@interceptors/jwt.interceptor';
import { ViewWrapperComponent } from '@views/view-wrapper/view-wrapper.component';
import { MainWrapperComponent } from '@views/main/main-wrapper/main-wrapper.component';
import { AuthWrapperComponent } from '@views/auth/auth-wrapper/auth-wrapper.component';
import { EncodeHttpParamsInterceptor } from '@interceptors/encode-http-params.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    MainWrapperComponent,
    ViewWrapperComponent,
    AuthWrapperComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonComponentsModule,
    DirectivesModule,
    HttpClientModule,
    PipesModule,
    BrowserAnimationsModule,
    MaterialModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncodeHttpParamsInterceptor,
      multi: true
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
