import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '@models/user.model';
import { AuthService } from '@services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MeResolver implements Resolve<Observable<User> | User> {
  constructor(private auth: AuthService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<Observable<User> | User> | Promise<Observable<User> | User> | Observable<User> | User {
    if (this.auth.isAuthenticated) {
      return this.auth.me ? this.auth.me : this.auth.getMe();
    } else {
      return null;
    }
  }
}
