import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  Output
} from '@angular/core';
import { File, Post } from '@models/post.model';
import { SwiperConfig } from 'ngx-swiper-wrapper';
import { Location } from '@models/location.model';
import { User } from '@models/user.model';
import { AuthService } from '@services/auth.service';
import { PostService } from '@services/api/modules/post.service';
import { Entity } from '@models/_base.model';

@Component({
  selector: 'post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss']
})
export class PostCardComponent implements AfterViewInit {
  @Input() post: Post;
  @Output() removePost: EventEmitter<Post> = new EventEmitter<Post>();
  @Output() ban: EventEmitter<Post> = new EventEmitter<Post>();
  @Output() banDevice: EventEmitter<Post> = new EventEmitter<Post>();
  config: SwiperConfig = new SwiperConfig({
    observer: true,
    observeParents: true,
    autoHeight: true
  });
  private pinnedTillAt: string;

  constructor(
    private postService: PostService,
    private authService: AuthService,
    private datepicker: ElementRef
  ) {}

  get photos(): File[] {
    return this.post.photos as File[];
  }

  get location(): Location {
    return this.post.location as Location;
  }

  get user(): User {
    return this.post && (this.post.user as User);
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.config.navigation = true), 0);
  }

  banUser(): void {
    this.ban.emit(this.post);
  }

  banUserDevice(): void {
    this.banDevice.emit(this.post);
  }

  deletePost(): void {
    this.removePost.emit(this.post);
  }

  updatePin(): void {
    console.log(this.pinnedTillAt);
    // console.log(Entity.reformatDateTime(this.pinnedTillAt));
    // this.postService.set(this.post, this.userId);
  }
}
