import { Entity, List } from '@models/_base.model';
import { Location } from '@models/location.model';
import { User } from '@models/user.model';

export interface File {
  id: number;
  contentType: string;
  name: string;
  path: string;
  thumbnailPath: string;
}

export class Post extends Entity {
  id: number;
  comments: number[];
  commentsCount: number;
  createdAt: string;
  pinnedTillAt: string;
  likesCount: number;
  location?: string | Location;
  message: string;
  photos: File[] | number[];
  public: boolean;
  user: number | User;

  protected setPropertiesToModel(key: string, data: any): void {
    switch (key) {
      case 'createdAt':
        this.createdAt = Entity.reformatDate(data[key]);
        break;
      case 'user':
        this.user = Entity.convertDataToModel(data[key], User);
        break;
      default:
        super.setPropertiesToModel(key, data);
    }
  }
}

export class PostList extends List {
  entities: Post[];
}
