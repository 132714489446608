import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@misc/constants';
import { HttpService, ServicesConfig } from '@services/http.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AdminStats } from '@models/admin.model';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(@Inject(APP_CONFIG) private config, private http: HttpService) {}

  getStats(services?: ServicesConfig): Observable<AdminStats> {
    return this.http
      .get(`${this.config.apiUrl}/api/admin/stats`, {}, services)
      .pipe(
        map((stats): AdminStats => new AdminStats(stats)),
        catchError((): Observable<AdminStats> => of({} as AdminStats))
      );
  }
}
