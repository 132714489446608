import { Entity, List } from '@models/_base.model';

export class Location extends Entity {
  id: string;
  country: string;
  locality?: string;
  subLocality: string;
  colloquialArea: string;
  administrativeAreaLevelOne: string;
  administrativeAreaLevelTwo: string;
  administrativeAreaLevelThree: string;
  postsCount: number;
  population: number;
  photo: number;
}

export class LocationList extends List {
  entities: Location[];
}
