import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '@directives/directives.module';
import { PipesModule } from '@pipes/pipes.module';
import { LoaderComponent } from './loader/loader.component';
import { MaterialModule } from '@components/material.module';
import { TopPanelComponent } from './top-panel/top-panel.component';
import { TableCustomComponent } from '@components/common/table-custom/table-custom.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NotFoundComponent } from '@components/common/not-found/not-found.component';
import { PostCardComponent } from '@components/common/post-card/post-card.component';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    DirectivesModule,
    PipesModule,
    ReactiveFormsModule,
    SwiperModule
  ],
  declarations: [LoaderComponent, TopPanelComponent, TableCustomComponent, NotFoundComponent, PostCardComponent],
  exports: [LoaderComponent, TopPanelComponent, TableCustomComponent, NotFoundComponent, PostCardComponent]
})
export class CommonComponentsModule {}
