import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG, STORAGE_KEYS } from '@misc/constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService, ServicesConfig } from '@services/http.service';
import { User } from '@models/user.model';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ApiService } from '@services/api/api.service';
import { Tokens } from '@models/token.model';
import { HttpRequest } from '@angular/common/http';

interface LoginParams {
  username?: string;
  password?: string;
  code?: string;
  grant_type?: string;
}

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  scope: any;
  token_type: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private shouldRemember$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private tokens$: BehaviorSubject<Tokens> = new BehaviorSubject<Tokens>(
    JSON.parse(this.getFromAnyStorage(STORAGE_KEYS.TOKEN)) as Tokens
  );
  me$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  private get storage(): Storage {
    return this.shouldRemember$.value ? localStorage : sessionStorage;
  }

  get isAuthenticated(): boolean {
    return !!(this.token && this.token.access && this.token.refresh);
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.tokens$.pipe(map(({ access, refresh }) => !!(this.me && access && refresh)));
  }

  get token(): Tokens {
    return this.tokens$.value;
  }

  get me(): User {
    return this.me$.value;
  }

  constructor(
    @Inject(APP_CONFIG) private config,
    private http: HttpService,
    private router: Router,
    private api: ApiService
  ) {}

  login(
    { username, password, grant_type = 'password', code }: LoginParams,
    shouldRemember: boolean,
    services?: ServicesConfig
  ): Observable<User> {
    const { apiUrl, client_id, client_secret } = this.config;
    this.shouldRemember$.next(shouldRemember);
    return this.http
      .post(
        `${apiUrl}/oauth/v2/token`,
        { username, password, client_id, client_secret, grant_type, code },
        {},
        services
      )
      .pipe(map(this.onTokenResponse.bind(this)), switchMap(this.getMe.bind(this)));
  }

  logout(): void {
    this.clearTokens();
    this.router.navigate(['', 'auth', 'log-in']);
  }

  refreshToken(): Observable<any> {
    const { apiUrl, client_id, client_secret } = this.config;

    return this.http
      .post(`${apiUrl}/oauth/v2/token`, {
        client_id,
        client_secret,
        grant_type: 'refresh_token',
        refresh_token: this.token.refresh
      })
      .pipe(map(this.onTokenResponse.bind(this)), switchMap(this.getMe.bind(this)));
  }

  getMe(services?: ServicesConfig): Observable<User> {
    return this.api.user.getMe(services).pipe(
      map(
        (user: User): User => {
          this.me$.next(user);
          return user;
        }
      )
    );
  }

  private onTokenResponse(res: AuthResponse): Tokens {
    let tokens: Tokens;

    if (res.access_token) {
      tokens = new Tokens(res);
      this.removeFromAllStorages(STORAGE_KEYS.TOKEN);
      this.storage.setItem(STORAGE_KEYS.TOKEN, JSON.stringify(tokens));
      this.tokens$.next(tokens);
    }

    return tokens;
  }

  private getFromAnyStorage(key: string) {
    const currentStorage: Storage = [sessionStorage, localStorage].find((storage: Storage): boolean =>
      Boolean(storage.getItem(key))
    );

    return (currentStorage && currentStorage.getItem(key)) || null;
  }

  clearTokens(): void {
    this.removeFromAllStorages(STORAGE_KEYS.TOKEN);
    this.tokens$.next(null);
    this.me$.next(null);
  }

  private removeFromAllStorages(key: string) {
    [sessionStorage, localStorage].forEach((storage: Storage): void => {
      storage.removeItem(key);
    });
  }

  addTokenToRequest(req: HttpRequest<any>): HttpRequest<any> {
    return this.token
      ? req.clone({
          setHeaders: {
            Authorization: `Bearer ${this.token.access}`
          }
        })
      : req;
  }

  changePassword(data): Observable<any> {
    return this.http.patch(`${this.config.apiUrl}/api/users/${this.me$.value.id}/change-pass`, data);
  }
}
