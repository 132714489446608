import { Entity, List } from '@models/_base.model';
import { Location } from '@models/location.model';

export interface Stats {
  posts: number;
  followers: number;
  followings: number;
}

export interface Avatar {
  id: number;
  contentType: string;
  name: string;
  path: string;
  thumbnailPath: string;
}

export class User extends Entity {
  id: number;
  email: string;
  enabled: boolean;
  location: string | Location;
  mention: string;
  name: string;
  description: string;
  avatar: Avatar;
  protectedProfile: boolean;
  isBanned: boolean;

  protected setPropertiesToModel(key: string, data: any): void {
    switch (key) {
      case 'location':
        this[key] = Entity.convertDataToModel(data[key], Location);
        break;
      default:
        super.setPropertiesToModel(key, data);
    }
  }
}

export class UsersList extends List {
  entities: User[];
}
