import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@misc/constants';
import { HttpService } from '@services/http.service';
import { Observable } from 'rxjs';
import { Post, PostList } from '@models/post.model';
import { catchError, map } from 'rxjs/operators';
import { List } from '@models/_base.model';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PostService {
  constructor(@Inject(APP_CONFIG) private config, private http: HttpService) {}

  remove(id: number): Observable<void> {
    return this.http.delete(`${this.config.apiUrl}/api/posts/${id}`);
  }

  getList(params?: Params): Observable<PostList> {
    return this.http
      .get(`${this.config.apiUrl}/api/posts`, { params })
      .pipe(map((posts: List) => new PostList(posts, Post)));
  }

  set(params: Post, id?: number): Observable<Post> {
    return this.http.patch(`${this.config.apiUrl}/api/posts/${id}`, params);
  }
  get(id?: number): Observable<Post> {
    return this.http.get(`${this.config.apiUrl}/api/posts/${id}`);
  }
}
