import { Component } from '@angular/core';
import { AuthService } from '@services/auth.service';

@Component({
  selector: 'top-panel',
  templateUrl: './top-panel.component.html',
  styleUrls: ['./top-panel.component.scss']
})
export class TopPanelComponent {
  constructor(public auth: AuthService) {}
}
