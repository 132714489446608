import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationLink } from '@misc/constants';

@Component({
  selector: 'main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss']
})
export class MainWrapperComponent implements OnInit {
  navigation: NavigationLink[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    const { navigation } = this.route.snapshot.data;
    this.navigation = navigation;
  }
}
